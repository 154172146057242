.bubble {
	margin: 0 auto;
	width: 320px;
	height: 65px;
	overflow: hidden;
	position: fixed;
	bottom: 10px;
	left: 10px;
	z-index: 999;
	border-bottom-left-radius: 40px;
	border-top-left-radius: 40px;
	border-bottom-right-radius: 40px;
	border-top-right-radius: 40px;
	background-color: #fff;
	box-shadow: 0 0 1px rgba(0, 0, 0, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05),
		0 8px 50px rgba(0, 0, 0, 0.05);
}

.bubble .poweredBy {
	font-family: 'Roboto', helvetica, arial, sans-serif;
	margin-left: 2px;
	display: inline-block;
	height: 15px;
	font-size: 10px;
	color: #bbb;
}

.bubble .poweredBy i {
	display: inline;
	margin: -3px 2px;
	vertical-align: middle;
	line-height: 1em;
	font-size: 0.75rem;
}

.bubble .poweredBy i svg {
	overflow: hidden;
}

.bubble .poweredBy a {
	color: #0089d8;
}

.bubble .image {
	display: block;
	position: absolute;
	top: 3px;
	left: 3px;
	height: 59px;
	width: 59px;
}

.bubble .image img {
	border-bottom-left-radius: 50%;
	border-top-left-radius: 50%;
	border-bottom-right-radius: 50%;
	border-top-right-radius: 50%;
	height: 59px;
	width: 59px;
}

.bubble .content {
	margin-left: 75px;
	padding-top: 9.28571px;
	padding-right: 4px;
}

.bubble .content .what,
.bubble .content .when,
.bubble .content .who {
	display: block;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.bubble .content .who {
	line-height: 16.25px;
	font-size: 14px;
	font-weight: bold;
	font-family: 'Roboto', helvetica, arial, sans-serif;
	color: #000;
}

.bubble .content .what {
	line-height: 16.25px;
	font-size: 12px;
	font-family: 'Roboto', helvetica, arial, sans-serif;
	color: #333;
}

.bubble .content .when {
	display: block;
	color: #999;
	font-size: 11px;
	font-family: 'Roboto', helvetica, arial, sans-serif;
}

@media (max-width: 768px) {
	.bubble {
		display: none;
	}
}
