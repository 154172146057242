.cta-widget {
	background-color: #ffffff; /* Light background for the panel */
	border-radius: 10px; /* Rounded corners */
	padding: 20px; /* Space inside the panel */
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Shadow for depth */
	margin: 20px; /* Space outside the panel */
	text-align: center; /* Center the text inside the panel */
	max-width: 427px; /* Maximum width of the panel */
	position: relative; /* Allows for absolute positioning inside */
}

.cta-widget img {
	max-width: 100%;
	height: auto;
}

.cta-container {
	overflow-y: scroll;
	height: 200%;
}

.cta-widget .btn {
	background-color: #3498db;
	color: white;
	border: none;
	padding: 10px 20px;
	border-radius: 5px;
	cursor: pointer;
	font-size: 16px;
	text-decoration: none;
}

.cta-widget h2 {
	font-size: 18px;
}

.cta-widget small {
	font-size: 10px;
	color: #888;
	margin-top: 10px;
}

.cta-widget p {
	font-size: 14px;
}

.closeButton {
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
	color: #333;
	background-color: #fff;
	border: 0;
}

@media (max-width: 480px) {
	.cta-widget {
		padding: 5px 2px;
	}

	.cta-widget img {
		height: 120px;
		width: auto;
	}
	.cta-container {
		overflow-y: scroll;
		height: 200%;
	}
}
/* .cta-widget {
	background-color: #ffffff;
	border-radius: 10px;
	padding: 20px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	position: relative;
	margin: 20px 20px 20px 20px;
	text-align: center;
	max-width: 427px;
}

.ctaWidget .content {
	position: relative;
}

.ctaWidget .title {
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 10px;
}

.specialPrice .title {
	margin-top: 0;
}

.ctaWidget .body {
	margin-bottom: 20px;
}

.ctaWidget .body p {
	margin: 0px;
	padding: 0;
}

.ctaWidget .prices {
	margin-bottom: 20px;
}

.ctaWidget .regularPrice {
	text-decoration: line-through;
	color: #888;
	margin-bottom: 0;
}

.ctaWidget .specialPrice {
	color: #e74c3c;
	font-size: 24px;
	font-weight: bold;
}



.ctaWidget .heroImage {
	width: 100%;
	height: auto;
	max-width: 150px;
}

.ctaWidget .ctaButton {
	display: inline-block;
	background-color: #3498db;
	color: white;
	border: none;
	padding: 10px 20px;
	border-radius: 5px;
	cursor: pointer;
	font-size: 16px;
	text-decoration: none;
	text-align: center;
}

.ctaWidget .disclaimer {
	font-size: 10px;
	color: #888;
	margin-top: 10px;
}

@media (max-width: 480px) {
	.ctaWidget {
		text-align: center;
		width: 100%;
		margin: 0px;
		padding: 0px;
		margin-top: 10px;
	}
	.ctaWidget .heroImage {
		display: none;
	}

	.ctaWidget .regularPrice,
	.ctaWidget .specialPrice {
		margin: 0;
		text-align: center;
	}

	.ctaWidget .body,
	.ctaWidget .prices {
		margin-bottom: 0;
		margin-top: 0;
	}
}

.closeButton {
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
	color: #333;
} */
