/* MobileNotification.css */
.mobileNotification {
	display: none; /* Default to not displayed */
}

@media (max-width: 768px) {
	.mobileNotification {
		display: block; /* Show on small screens */
		background-color: #ffcccc; /* Light red background */
		color: #cc0000; /* Darker red text */
		padding: 10px; /* Padding around the text */
		margin: 0px 0; /* Margin at the top and bottom */
		border: 1px solid #cc0000; /* Dark red border */
		border-radius: 5px; /* Rounded corners */
		text-align: center; /* Centered text */
		font-weight: bold; /* Bold text */
		font-size: 12px; /* Smaller font size */
	}
}
