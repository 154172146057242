.container {
	width: 100%;
	max-width: 1200px; /* Standard max-width for desktop */
	margin: 0 auto; /* Center the container */
	padding: 0 15px; /* Optional padding */
}

body {
	font-family: Arial, sans-serif;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.CalcAppApp {
	text-align: center;
	padding: 10px;
}

.input-container {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin-bottom: 20px;
}

.input-container label {
	margin: 5px;
	font-size: 14px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

input,
select {
	margin-top: 5px;
	padding: 10px;
	border-radius: 5px;
	border: 1px solid #ccc;
	outline: none;
	width: 150px;
}

input:focus,
select:focus {
	border-color: #007bff;
}

.reset-link {
	cursor: pointer;
	color: #007bff;
	text-decoration: underline;
	margin-bottom: 10px;
}

.reset-link:hover {
	color: #0056b3;
}

@media (max-width: 600px) {
	.container {
		max-width: 100%; /* Full width on mobile */
		padding: 0 10px; /* Optional padding for mobile */
	}

	.input-container {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 10px;
		justify-items: center;
		width: 100%;
	}

	.input-container label {
		margin-bottom: 10px;
		font-size: 12px;
		width: 100%;
	}

	input,
	select {
		width: 100%;
	}

	.reset-link {
		grid-column: span 2;
		justify-self: center;
	}
}
