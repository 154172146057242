.calculator {
	margin: 10px auto;
	max-width: 100%;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 10px;
	background-color: #f9f9f9;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.chart-container {
	height: 400px;
	margin-bottom: 10px;
}

table {
	width: 100%;
	border-collapse: collapse;
	margin-top: 20px;
	font-size: 12px;
}

table th,
table td {
	border: 1px solid #ddd;
	padding: 5px;
	text-align: right;
}

table th {
	background-color: #007bff;
	color: white;
}

table td:first-child {
	text-align: left;
}

.highlight-row {
	background-color: #e0f7fa;
	font-weight: bold;
}

@media (max-width: 600px) {
	.calculator {
		padding: 5px;
	}

	table th,
	table td {
		padding: 3px;
	}

	.input-container {
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}

	.input-container input,
	.input-container select {
		width: auto !important;
	}

	.alert-box {
		flex: 1;
		background-color: #e0f7fa;
		padding: 10px;
		border-radius: 5px;
		margin-left: 10px;
		font-size: 10px;
		text-align: left;
	}
}
