.project {
	margin-bottom: 20px;
}

.project h2 {
	background: #007bff;
	color: #fff;
	padding: 10px;
	margin: 0 -20px 10px;
}

.session {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 0;
	border-bottom: 1px solid #ddd;
}

.session:last-child {
	border-bottom: none;
}

.join-button {
	background: #28a745;
	color: #fff;
	border: none;
	padding: 5px 10px;
	cursor: pointer;
}

.join-button:hover {
	background: #218838;
}
