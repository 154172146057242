.ErrorPage {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 80%;
	margin: auto;
	color: #333;
	font-family: 'Arial', sans-serif;
	text-align: center;
	padding: 20px;
	box-sizing: border-box;
}

.ErrorPage h1 {
	font-size: 3em;
	margin-bottom: 20px;
}

.ErrorPage p {
	font-size: 1.5em;
	margin-bottom: 20px;
}

.ErrorPage img {
	max-width: 50%;
	height: auto;
}
