/* Webinar.css */
.webinar-container {
	display: flex;
	flex-direction: row;
	height: 100vh;
	width: 100%;
	position: relative;
	overflow: hidden;
}

.video-section {
	flex: 3;
	background-color: #1c1e21;
	padding: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.5s ease-in-out;
}

.webinar-finished {
	color: #ffffff;
	padding: 30px;
	text-align: center;
}

.webinar-finished h1 {
	font-size: 18px;
}

.video-section.minimized {
	flex: none;
	width: 300px;
	height: 168.75px; /* Maintain 16:9 Aspect Ratio */
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 999;
	background-color: transparent;
}

.chat-section {
	flex: 1;
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	border-left: 1px solid #ececec;
	overflow: hidden;
}

.chat-section.minimized {
	display: none;
}

.page-reveal {
	flex: 1;
	background-color: #f7f7f7;
	overflow: auto;
	position: relative;
}

.sales-page-iframe {
	width: 100%;
	height: 100%;
	border: none;
}

.toggle-chat-button {
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 1000;
	background-color: #007bff;
	color: #ffffff;
	padding: 10px 15px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
}

.expand-button {
	background-color: #808080;
	border: none;
	color: white;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	position: fixed;
	bottom: 170px;
	right: 20px;
	z-index: 1000;
	padding: 5px 5px 0 5px;
	border-radius: 5px;
	cursor: pointer;
}

.minimized .sales-page {
	display: block;
}

.minimized .toggle-chat-button {
	bottom: 10px;
	right: 10px;
	background-color: #28a745;
}

/* Mobile responsive styles */
@media (max-width: 768px) {
	.webinar-container {
		display: flex;
		flex-direction: column;
		height: calc(100vh - 82px) !important;
	}

	.video-section,
	.chat-section {
		flex: 1;
	}

	.video-section {
		padding: 0px;
		width: 100%; /* Take up the full width */
		flex-grow: 0; /* Don't grow beyond content size */
		flex-shrink: 0; /* Don't shrink below content size */
	}

	.chat-section.minimized {
		position: fixed;
		top: 20px;
		right: 10px;
		width: calc(100% - 20px);
		height: calc(100% - 220px);
		border-radius: 5px;
	}

	.chat-section {
		padding-top: 0px;
		flex-grow: 1; /* Grow to take up remaining space */
		overflow: auto; /* Add a scrollbar if the content overflows */
	}

	.toggle-chat-button {
		bottom: 5px;
		right: 5px;
	}
}
