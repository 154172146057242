/* ChatBox.css */
.main-container {
	width: 100%;
	height: 100%;
}

.chat-container {
	width: 100%;
	height: 100%;
}

.cs-message__header {
	margin-top: 14px;
}
