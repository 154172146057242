.countdownTimer {
	font-size: 20px;
	color: #ffffff;
	background-color: #e74c3c;
	padding: 10px;
	border-radius: 5px;
	text-align: center;
}

@media (max-width: 480px) {
	.countdownTimer {
		font-size: 12px;
		color: #ffffff;
		background-color: #e74c3c;
		padding: 10px;
		border-radius: 5px;
		text-align: center;
	}
}
