/* VideoPlayer.css */
.video-player-wrapper {
	position: relative;
	width: 100%;
	padding-top: 56.25%; /* 16:9 Aspect Ratio */
	transition: all 0.5s ease-in-out;
}

.video-player-wrapper.minimized {
	position: fixed;
	bottom: 20px;
	right: 20px;
	width: 300px;
	height: 168.75px; /* Maintain 16:9 Aspect Ratio */
	padding-top: 0;
	z-index: 999;
	background-color: transparent; /* Ensure no background */
}

.video-player {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: contain;
	border-radius: 10px;
	overflow: hidden;
	background-color: transparent;
}

.live-indicator {
	position: absolute;
	top: 10px;
	left: 10px;
	z-index: 1000;
	background-color: red;
	color: #ffffff;
	padding: 5px 10px;
	border-radius: 5px;
	font-weight: bold;
}

.replay-indicator {
	position: absolute;
	top: 10px;
	left: 10px;
	z-index: 1000;
	background-color: blue;
	color: #ffffff;
	padding: 5px 10px;
	border-radius: 5px;
	font-weight: bold;
}

.webinar-start-button {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1000;
	background-color: #28a745;
	color: #ffffff;
	padding: 15px 25px;
	border: none;
	border-radius: 10px;
	font-size: 1.2em;
	font-weight: bold;
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 10px;
}

.webinar-start-button i {
	font-size: 1.5em;
}

.minimize-button {
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 1000;
	background-color: #007bff;
	color: #ffffff;
	padding: 5px 10px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
}
