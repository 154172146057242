.toggle-button {
	position: absolute;
	top: 20px;
	right: 20px;
	z-index: 10;
	padding: 10px 20px;
	background-color: rgba(0, 0, 0, 0.5);
	color: white;
	border: none;
	border-radius: 5px;
	cursor: pointer;
}

.webcam-preview {
	position: absolute;
	bottom: 2%;
	right: 25%;
	width: 12%;
	height: auto;
	z-index: 5;
	border: 2px solid white;
	border-radius: 5px;
}

/* on mobile */
@media (max-width: 768px) {
	.webcam-preview,
	.toggle-button {
		display: none;
	}
}
